import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignInSide from "./SignInSide";
import Dashboard from "./scenes/dashboard/Dashboard";
import Financials from "./scenes/dashboard/Financials.jsx";
import Summary from "./scenes/dashboard/Summary.jsx";
import Help from "./scenes/Informations/Help.jsx";
import PrivateRoutes from "./scenes/global/PrivateRoutes.jsx";


function App() {
  return (
    <div className="App">

      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignInSide />} />
          <Route element={<PrivateRoutes />}>
          <Route path="/43213598" element={<PrivateRoutes />}>
            <Route path="dashboards" element={<Dashboard/>} />
            <Route path="details" element={<Financials/>} />
            <Route path="home" element={<Summary/>} />
            <Route path="help" element={<Help/>} />
            </Route>  
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;
