import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios'; // or use fetch
import PrivateLayout from './PrivateLayout';

const PrivateRoutes = () => {
    const [auth, setAuth] = useState({ token: false, isLoading: true });

    useEffect(() => {
        const verifyUser = async () => {
            try {
                // const response = await axios.get('/api/isUserAuth/', {
                //     withCredentials: true
                // });
                const response = await axios.get('/api/isUserAuth/');

          
                if (response.data.auth) {
                    setAuth({ token: true, isLoading: false });
                } else {
                    setAuth({ token: false, isLoading: false });
                }
            } catch (error) {
                console.error('Error verifying token', error);
                setAuth({ token: false, isLoading: false });
            }
        };

        verifyUser();
    }, []);

    // Show nothing or a loader while the authentication status is loading
    if (auth.isLoading) {
        return <div>Loading...</div>; // Replace with a proper loader component as needed
    }

    if (!auth.token) {
        // Redirect to the external URL
        window.location.href = "/";
        return null; // Render nothing or some kind of loading indicator
    }

    // User is authenticated, render the child routes
    return (
         <PrivateLayout>
          <Outlet />
         </PrivateLayout> 
      );
}

export default PrivateRoutes;
