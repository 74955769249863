import React from 'react';
import { Modal, Box, Typography, useMediaQuery, useTheme, Alert } from '@mui/material';

function MobileNotAllowed() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Modal
            open={isMobile}
            aria-labelledby="mobile-block-modal-title"
            aria-describedby="mobile-block-modal-description"
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } }}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                textAlign: 'center',
            }}>
                <Typography id="mobile-block-modal-title" variant="h6" component="h2">
                    Appareil non pris en charge
                </Typography>
                <Typography id="mobile-block-modal-description" sx={{ mt: 2 }}>
                    Veuillez accéder à cette application à partir d'un ordinateur pour une expérience optimale.
                </Typography>
            </Box>
        </Modal>
    );
}

export default MobileNotAllowed;
