import * as React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Help() {
  return (
    <Box
    width={'100%'}
    display={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    p={4}
>
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-help-content"
          id="panel-help-header"
        >
          <Typography>Contactez-Nous</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Si vous avez des questions ou avez besoin d'assistance, veuillez nous contacter:
          </Typography>
          <ul>
            <li>Email: support@votreentreprise.com</li>
            <li>Téléphone: +33 1 23 45 67 89</li>
            <li>Horaires: Lundi - Vendredi, 9h - 17h</li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-faq-content"
          id="panel-faq-header"
        >
          <Typography>FAQ</Typography>
        </AccordionSummary>
        <AccordionDetails>
  <Typography>
    Voici quelques réponses aux questions fréquemment posées :
  </Typography>
  <Typography component="div">
    <ul>
      <li>
        <strong>Les icônes en haut à droite ne fonctionnent pas :</strong> L'application est actuellement en cours de développement. Si vous rencontrez des problèmes spécifiques ou si vous avez des besoins particuliers, n'hésitez pas à nous contacter par email à support@votreentreprise.com. Votre feedback est précieux et nous aide à améliorer notre service.
      </li>
      {/* Ajoutez d'autres questions/réponses si nécessaire */}
    </ul>
  </Typography>
</AccordionDetails>

      </Accordion>
      </Box>

      {/* Ajoutez d'autres sections d'aide ou de contact si nécessaire */}
    </Box>
  );
}
