import * as React from 'react';
import { useEffect, useMemo, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './public/DatafactoryLogo.png';
import { Snackbar, Alert } from '@mui/material';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadBasic } from "@tsparticles/basic"; 






// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();




export default function SignInSide() {
  console.log("login")

  //PARTICULES
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadBasic(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      fullScreen: { enable: false },
      background: {
        color: {
          value: "#0d47a1",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 6,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    [],
  );




  //handle snackbar visibility 
const [openSnackbar, setOpenSnackbar] = React.useState(false);
const [snackbarMessage, setSnackbarMessage] = React.useState('');

//handle snackbar close
const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnackbar(false);
};

async function handleSubmit(event) {
  event.preventDefault(); // This stops the default form submission behavior

  // Retrieve the form data
  const data = new FormData(event.currentTarget);

  // Create the object to be sent
  const loginDetails = {
    login: data.get('login'), // Retrieve the login from the form data
    password: data.get('password'), // Retrieve the password from the form data
  };

  console.log(loginDetails)

  // Use the fetch API to send the data as a POST request to your server
  try {
    const response = await fetch('/api/login/', {
      
      method: 'POST', // Specify the request method

      headers: {
        'Content-Type': 'application/json', // Specify the type of content expected
      },
      body: JSON.stringify(loginDetails), // Convert the JavaScript object to a JSON string
    });

    console.log(response)

    // Check if the request was successful
    if (response.ok) {
      const jsonResponse = await response.json();
    

      // Check if authentication was successful
      if (jsonResponse.auth) {
        // Save the token for future requests
 
        // Save the token in a cookie for future requests
        document.cookie = `token=${jsonResponse.token};max-age=300;path=/;Secure;samesite=None;domain=tourinvest.godatafactory.com`;


        console.log('Login successful:', jsonResponse); // Handle the response data
        // You might want to redirect the user or update the state to reflect the successful login here
        const id = jsonResponse.user.id
        console.log(id)
        window.location.href = `/${id}/dashboards`;
      } else {
        console.error('Login failed:', jsonResponse.message); // Handle failed authentication
 

      }
    } else {
      console.error('Request failed:', response.status); // Handle errors
      setSnackbarMessage('Nom de compte ou mot de passe incorrect'); // Set the message for the Snackbar
      setOpenSnackbar(true); // Open the Snackbar
    }
  } catch (error) {
    console.error('There was an error!', error); // Handle network errors
   
  }
}




  return (
    <ThemeProvider theme={defaultTheme}>
       <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          

          

          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              zIndex: 2,
             position: 'relative',
            }}
          >
            


            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Connexion
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="login"
                label="Compte"
                name="login"
                autoComplete="login"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Se connecter
              </Button>             
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

