// PrivateLayout.jsx
import React, { useState, useEffect } from 'react';
import SidebarContainer from "../global/Sidebar";
import Topbar from './Topbar';

function PrivateLayout({ children }) {
  const [renderSidebar, setRenderSidebar] = useState(false);
  const [renderTopbar, setRenderTopbar] = useState(false);

  useEffect(() => {
    // Check if SidebarContainer and Topbar are already in the DOM
    const sidebarExists = document.getElementById('sidebar-container');
    const topbarExists = document.getElementById('topbar-container');

    if (!sidebarExists) {
      setRenderSidebar(true);
    }
    if (!topbarExists) {
      setRenderTopbar(true);
    }
  }, []);

  return (
    <div className="private-layout">
      {renderSidebar && <div id="sidebar-container"><SidebarContainer /></div>}
      <main className="content">
        {renderTopbar && <div id="topbar-container"><Topbar /></div>}
        {children}
      </main>
    </div>
  );
}

export default PrivateLayout;
