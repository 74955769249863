import React from 'react'
import { tokens } from '../../theme'
import { Box, IconButton, useTheme } from '@mui/material'
import { InputBase } from '@mui/material';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";


const Topbar = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.primary.main)
    return (
        <Box 
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '45px',
          width: '100%',
          padding: '5px 24px',
          justifyContent: 'space-between',
          // Apply responsive styles:
          '@media (max-width:600px)': { // For small screens
            flexDirection: 'column',
            height: 'auto',
            padding: '0 12px',
          },
          '@media (min-width:601px) and (max-width:1024px)': { // For medium screens
            padding: '0 18px',
          },
          // Add more media queries as needed for larger screens
        }}
        >
          {/* SEARCH BAR */}
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
          </Box>
    
          {/* ICONS */}
          <Box display="flex">
            <IconButton>
                <LightModeOutlinedIcon />             
            </IconButton>
            <IconButton>
              <NotificationsOutlinedIcon />
            </IconButton>
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
            <IconButton>
              <PersonOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      );
    };

export default Topbar